<template>
    <div v-if="content != null" id="about-container">
        <div id="scroll-container">
            <section v-for="(section, index) of content" :key="index">
                <h1>{{ section.title }}</h1>
                <p v-for="(paragraph, index) of section.content" :key="index">{{ paragraph }}</p>
            </section>
        </div>
    </div>
</template>
    
<script>
export default {
    name: 'AboutPage',
    data() {
        return {
            content: null,
        }
    },
    mounted() {
        fetch('/configs/about_page_content.json').then(response => {
            return response.json();
        }).then(data => {
            this.content = data;
        })
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#about-container {
    top: 100px;
    bottom: 93px;
    right: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
}


#scroll-container {
    height: 90%;
    max-width: 760px;
    padding: 20px 40px;
    overflow: scroll !important;
    mask-image: linear-gradient(to bottom, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
}



h1 {
    font-weight: 400;
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 18px;
    text-align: start;
    text-transform: uppercase;
}

p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 18px;
    margin-top: 0px;
    text-align: start;
    text-transform: uppercase;
    color: #999999;
    white-space: pre-line;
}


/* Tablet */
@media only screen and (min-width: 481px) {}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {

    h1 {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 22px;
    }

    p {
        line-height: 22px;
    }

    #scroll-container {
        max-width: 396px;
        /* padding: 20px 0px; */
    }

    #about-container {
        top: 200px;
        bottom: 193px;
    }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {
    h1 {
        font-size: 20px;
        margin-bottom: 0px;
        line-height: 22px;
    }

    p {
        line-height: 22px;
    }

    #scroll-container {
        max-width: 396px;
        /* padding: 20px 0px; */
    }
}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {}
</style>
    