<template>
  <div id="home-container">
    <image-wrapper id="lichtreflex-image" alt="logo" :src="require('../assets/images/lichtreflex.jpg')" />
    <router-link id="about" class="link" :to="{ name: 'about' }">
      about
    </router-link>
    <div id="contact" class="link">
      <a v-show="showContact" id="insta-big" class="secondary big" href="https://www.instagram.com/philiptset"
        target="_blank">
        instagram</a>
      <span @click="toggleShowContact">contact</span>
      <div class="contact-spacer"></div>
      <Transition>
        <a v-show="showContact" class="secondary small" href="https://www.instagram.com/philiptset" target="_blank">
          instagram</a>
      </Transition>
      <div class="contact-spacer small"></div>
      <Transition>
        <div v-show="showContact" class="secondary" id="email">
          <a class="secondary big" :href="'mailto:' + email">contact@philip-tsetinis.com</a>
          <a class="secondary small" :href="'mailto:' + email">email</a>
          <div class="email-spacer small"></div>
          <div class="small">
            <image-wrapper @click="copyEmail" :src="require('../assets/icons/clipboard.svg')" />
          </div>
        </div>
      </Transition>
    </div>
    <div id="work" class="link">
      <Transition>
        <router-link v-show="showWork" class="secondary" :to="{ name: 'commissions' }"> comissions</router-link>
      </Transition>
      <div class="work-spacer"></div>
      <Transition>
        <router-link v-show="showWork" class="secondary" :to="{ name: 'projects' }"> projects</router-link>
      </Transition>
      <div class="work-spacer"></div>
      <span @click="toggleShowWork">work</span>
    </div>

    <img class="ghost-image" src="/images/work/projects/unkown_polyphenism/absorption.jpg" />
    <img class="ghost-image" src="/images/work/projects/photons/gerinnsel_02.jpg" />
    <div id="commissions" v-if="showWork"></div>
  </div>
</template>
  
<script>
import ImageWrapper from '@/components/ImageWrapper.vue';

export default {
  components: { ImageWrapper },
  name: 'HomePage',
  data() {
    return {
      email: "contact@philip-tsetinis.com",
      showWork: false,
      showContact: false,
    }
  },
  methods: {
    copyEmail() {
      if (!navigator.clipboard) {
        alert("Could not copy email to clipboard")
        return;
      }
      navigator.clipboard.writeText(this.email).then(function () {
        alert("Copied email to clipboard")
      }, function () {
        alert("Could not copy email to clipboard")
      });
    },
    toggleShowContact() {
      this.showWork = false;
      this.showContact = !this.showContact;
    }, toggleShowWork() {
      this.showContact = false;
      this.showWork = !this.showWork;
    },
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#home-container {
  position: absolute;
  bottom: 93px;
  right: 40px;
  height: 230px;
  width: 176px;
}

#lichtreflex-image {
  height: 100%;
  width: 100%;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

.link {
  position: absolute;
}

.link span:hover {
  cursor: pointer;
}

#about {
  bottom: 100%;
  margin-bottom: 36px;
  right: 0px;
}

#contact {
  right: 100%;
  margin-right: 37px;
  bottom: 0px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
}

#email {
  display: flex;
}

#email div {
  height: 13px;
}

#email img {
  transform: rotate(90deg);
}

#email img:hover {
  cursor: pointer;
}

#work {
  top: 100%;
  margin-top: 39px;
  right: 0px;
  display: flex;
  align-items: center;
}

.email-spacer {
  height: 15px !important;
}

.work-spacer {
  width: 20px;
}

.contact-spacer {
  height: 28px;
}


.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.big {
  display: none;
}

/* Tablet */
@media only screen and (min-width: 481px) {}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {
  .link {
    letter-spacing: 0.1rem;
  }

  .work-spacer {
    width: 28px;
  }

  #home-container {
    width: 183px;
    height: 240px;
    position: relative;
    bottom: 0px;
    right: 0px;
  }

  #about {
    margin-bottom: 62px;
  }

  #contact {
    margin-right: 93px;
  }

  #insta-big {
    position: absolute;
    bottom: 100%;
    margin-bottom: 28px;
  }

  #work {
    top: 100%;
    margin-top: 93px;
    right: auto;
    left: 0px;
    display: flex;
    flex-direction: row-reverse;

  }

  .secondary {
    font-size: 13.5px;
    white-space: nowrap;
  }

  .big {
    display: unset;
  }

  .small {
    display: none;
  }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {
  .link {
    letter-spacing: 0.1rem;
  }

  #home-container {
    width: 183px;
    height: 240px;
    position: relative;
    bottom: 0px;
    right: 0px;
  }

  #about {
    margin-bottom: 62px;
  }

  #contact {
    margin-right: 93px;
  }

  #insta-big {
    position: absolute;
    bottom: 100%;
    margin-bottom: 28px;
  }

  #work {
    top: 100%;
    margin-top: 93px;
    right: auto;
    left: 0px;
    display: flex;
    flex-direction: row-reverse;

  }



  .secondary {
    font-size: 13.5px;
    white-space: nowrap;
  }

  .big {
    display: unset;
  }

  .small {
    display: none;
  }
}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {}
</style>
  