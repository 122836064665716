<template>
    <ImageSlider v-if="images !== null" :images="images"></ImageSlider>
</template>

<script>
import ImageSlider from '../../../../components/ImageSlider.vue';
export default {
    name: 'PhotonsPage',
    components: {
        ImageSlider,
    },
    data() {
        return {
            images: null
        }
    },
    mounted() {
        fetch('/configs/work_images.json').then(response => {
            return response.json();
        }).then(data => {
            this.images = data.projects.photons;
        })
    }
}
</script>