<template>
    <div id="projects">
        <router-link class="projects-content" :to="{ name: 'unkown-polyphenism' }">
            <image-wrapper src="/images/work/projects/unkown_polyphenism/absorption.jpg" />
            <div class="projects-content-spacer"></div>
            <div>
                Unknown Polyphenism
            </div>
        </router-link>
        <div class="projects-spacer"></div>
        <router-link class="projects-content" :to="{ name: 'photons' }">
            <image-wrapper src="/images/work/projects/photons/gerinnsel_02.jpg" />
            <div class="projects-content-spacer"></div>
            <div>
                Photons
            </div>
        </router-link>
    </div>
</template>
    
<script>
export default {
    name: 'ProjectsPage',
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#projects {
    display: flex;
    flex-direction: column;
}

.projects-spacer {
    height: 17px;
}

.projects-content {
    display: flex;
    flex-direction: column;
    width: 99px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: start;
    font-style: normal;
}

.projects-content img {
    width: 99px;
    height: 126px;
}


/* Tablet */
@media only screen and (min-width: 481px) {}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {
    .projects-spacer {
        height: 74px;
    }

    .projects-content {
        line-height: 16px;
        font-size: 13px;
        position: relative;
    }

    .projects-content div {
        position: absolute;
        top: 100%;
        margin-top: 14px;
    }

    .projects-content img {
        width: 135px;
        height: 172px;
    }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {}
</style>
    