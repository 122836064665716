import { createStore } from 'vuex'


// Create a new store instance.
const store = createStore({
    state() {
        return {
            isLoading: false,
        }
    },
    mutations: {
        activateLoading(state) {
            state.isLoading = true;
        },
        deactivateLoading(state) {
            state.isLoading = false;
        }
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        }
    }
})


export default store;