import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue'
import CommissionsPage from '../pages/work/commissions/CommissionsPage.vue'
import ProjectsPage from '../pages/work/projects/ProjectsPage.vue'
import AboutPage from '../pages/about/AboutPage.vue'
import UnkownPolyphenismPage from '../pages/work/projects/unkown_polyphenism/UnkownPolyphenismPage.vue'
import PhotonsPage from '../pages/work/projects/photons/PhotonsPage.vue'
import NotFoundPage from '../pages/NotFoundPage.vue'

const routes = [
    {
        path: '/',
        component: HomePage,
        name: 'home',
        props: { showText: false },
        meta: {
            displayName: null,
            parentRoute: null,
        }
    },
    {
        path: '/about',
        name: 'about',
        component: AboutPage,
        meta: {
            displayName: 'About',
            parentRoute: null
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: ProjectsPage,
        meta: {
            displayName: null,
            parentRoute: {
                name: 'projects',
                displayName: 'Projects'
            },
        }
    },
    {
        path: '/commissions',
        name: 'commissions',
        component: CommissionsPage,
        meta: {
            displayName: 'Commissions',
            parentRoute: null
        }
    },
    {
        path: '/projects/unkown-polyphenism',
        name: 'unkown-polyphenism',
        component: UnkownPolyphenismPage,
        meta: {
            displayName: 'Unkown Polyphenism',
            parentRoute: {
                name: 'projects',
                displayName: 'Projects'
            },
        }
    },
    {
        path: '/projects/photons',
        name: 'photons',
        component: PhotonsPage,
        meta: {
            displayName: 'Photons',
            parentRoute: {
                name: 'projects',
                displayName: 'Projects'
            },
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFoundPage,
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;