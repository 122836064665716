<template>


  <div id="content">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>


  <div class="current-page">
    {{ $route.meta.displayName }}
  </div>

  <router-link class="header" :to="{ name: 'home' }">
    Philip Tsetinis
  </router-link>
  <the-rectangle></the-rectangle>
  <div @click="$router.push({ name: 'home' })"></div>
  <navigation-bar></navigation-bar>


</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import TheRectangle from './components/TheRectangle.vue';
export default {
  name: 'App',
  components: {
    NavigationBar,
    TheRectangle
  },
  methods: {
  },

  mounted() {
    window.addEventListener('resize', this.setAppHeight)
  },
  beforeUnmount() { window.removeEventListener('resize', this.setAppHeight) }
}
</script>
<!-- maybe remove unused fonts here-->
<style>
@font-face {
  font-family: "PPNeueMontreal";
  src: local("PPNeueMontreal"),
    url(./assets/fonts/pp_neue_montreal/PPNeueMontreal-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: local("PPNeueMontreal"),
    url(./assets/fonts/pp_neue_montreal/PPNeueMontreal-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "PPNeueMontreal";
  src: local("PPNeueMontreal"),
    url(./assets/fonts/pp_neue_montreal/PPNeueMontreal-Medium.ttf) format("truetype");
  font-weight: 500;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  padding: 0px;
  margin: 0px;
  color: black;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "PPNeueMontreal", Helvetica, Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
}

.header {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 15px;
  font-weight: 500;
  max-height: 60px;
  top: 37px;
  right: 40px;
  position: absolute;
}

#content {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex-grow: 1;
  overflow: hidden;
  height: 0px;
}

.current-page {
  position: absolute;
  left: 39px;
  right: 120px;
  bottom: 37px;
  font-size: 15px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: start;
}

a {
  text-decoration: none;
  color: black
}

.link {
  font-size: 15px;
  user-select: none;
  text-transform: uppercase;
}

.secondary {
  color: #999999 !important
}

.ghost-image {
  height: 0px;
  width: 0px;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Tablet */
@media only screen and (min-width: 481px) {}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {
  .header {
    top: 56px;
    right: 56px;
    font-size: 22.5px;
    line-height: 27px;
    font-weight: 400;
  }

  .current-page {
    left: 56px;
    bottom: auto;
    top: 56px;
    font-size: 20px;
    line-height: 24px;
  }

  .link {
    font-size: 20px;
  }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {
  .header {
    top: 64px;
    right: 70px;
    font-size: 22.5px;
    line-height: 27px;
    font-weight: 400;
  }

  .current-page {
    left: 70px;
    bottom: auto;
    top: 64px;
    font-size: 20px;
    line-height: 24px;
  }

  .link {
    font-size: 20px;
  }
}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {}
</style>
