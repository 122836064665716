<template>
    <div class="not-found">
        404 PAGE NOT FOUND
    </div>
</template>
    
<script>
export default {
    name: 'NotFoundPage',
    props: {
        showText: Boolean
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.not-found {
    font-size: 36px;
}
</style>
    