<template>
    <div id="the-rectangle" :class="{ 'pulse': isLoading }" @click="onClick"></div>
</template>
    
<script>
export default {
    name: 'TheRectangle',
    methods: {
        onClick() {
            this.$router.push({ name: 'home' });
            this.$store.commit('deactivateLoading');
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        }
    },

}
</script>


<style scoped>
#the-rectangle {
    width: 40px;
    height: 53px;
    background-color: black;
    position: absolute;
    top: 40px;
    left: 40px;
}

#the-rectangle:hover {
    cursor: pointer;
}

.pulse {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 1.2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}


/* Tablet */
@media only screen and (min-width: 481px) {}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {
    #the-rectangle {
        width: 50px;
        height: 64px;
        bottom: 56px;
        left: 56px;
        top: auto;
    }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {
    #the-rectangle {
        width: 77px;
        height: 98px;
        top: auto;
        bottom: 70px;
        left: 70px;
    }
}
</style>