<template>
    <div class="container">
        <!-- This is needed to make sure that the carousel does not render although images are not evaluated yet. Used for preloading the images (which are in turn reused in the carousel) -->
        <div v-if="!isLoaded"><img class="ghost-image" @load="increaseLoadedCounter" v-for="(image, index) in images"
                :key="index" :src="image.path" /></div>
        <!-- using transition here to also show an animation in case carousel was loaded with delay (initial load) -->
        <transition>
            <!-- Only showing carousel in case list is initialized & images are loaded -->
            <Carousel v-if="isLoaded" :items-to-show="imagesToShow" :wrap-around="true" class="image_carousel"
                ref="myCarousel">
                <Slide v-for="(image, index) in images" :key="index" @click="onSlideClick(index)">
                    <div class="carousel-image">
                        <image-wrapper :src="image.path" />
                    </div>
                </Slide>

            </Carousel>
        </transition>
        <full-screen-modal v-if="isLoaded" v-model="showModal" :images="images" :start-index="activeIndex"
            @slideChange="slideTo">
        </full-screen-modal>
    </div>
</template>
    
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, } from 'vue3-carousel';
import FullScreenModal from './FullScreenModal.vue';

export default {
    name: 'ImageSlider',
    components: {
        Carousel,
        Slide,
        FullScreenModal,
    },
    props: {
        images: Array
    },
    data() {
        return {
            loadedImages: 0,
            imagesToShow: 1,
            activeIndex: 0,
            showModal: false,
        }
    },
    computed: {

        isLoaded() {
            return (this.images.length != 0) && (this.images.length == this.loadedImages);
        },
        currentSlideIndex() {
            const myCarousel = this.$refs.myCarousel;
            return myCarousel.data.currentSlide.value;
        },
        slidesCount() {
            const myCarousel = this.$refs.myCarousel;
            return myCarousel.data.slidesCount.value;
        },
    },
    methods: {
        next() {
            const myCarousel = this.$refs.myCarousel;
            myCarousel.next();
        },
        back() {
            const myCarousel = this.$refs.myCarousel;
            myCarousel.prev();
        },
        slideTo(index) {
            const myCarousel = this.$refs.myCarousel;
            myCarousel.slideTo(index);
        },
        onSlideClick(index) {
            if (this.currentSlideIndex < index) {
                if (this.currentSlideIndex === 0 && index === (this.slidesCount - 1)) {
                    this.back();
                }
                this.next();
            } else if (this.currentSlideIndex > index) {
                if (index === 0 && this.currentSlideIndex === (this.slidesCount - 1)) {
                    this.next();
                }
                this.back();
            } else {
                this.activeIndex = this.currentSlideIndex;
                this.showModal = true;
            }
        },
        increaseLoadedCounter() {
            this.loadedImages++;
        },
        adjustImagesToShow() {
            if (window.innerWidth < 768) {
                this.imagesToShow = 1.6
            } else if (window.innerWidth < 992) {
                this.imagesToShow = 1.6
            } else if (window.innerWidth < 1200) {
                this.imagesToShow = 1.8
            } else if (window.innerWidth < 1600) {
                this.imagesToShow = 1.8
            } else {
                this.imagesToShow = 2
            }
        },
        onButtonPress(e) {
            if (e.key === "ArrowRight") {
                if (!this.showModal) {
                    this.next();
                }

            } else if (e.key === "ArrowLeft") {
                if (!this.showModal) {
                    this.back();
                }
            }
            else if (e.key === "Enter") {
                if (!this.showModal) {
                    this.activeIndex = this.currentSlideIndex;
                    this.showModal = true;
                }

            }
        }
    },
    created() {
        window.addEventListener('resize', this.adjustImagesToShow)
    },
    mounted() {
        this.$store.commit('activateLoading');
        this.adjustImagesToShow();
        window.addEventListener("keydown", this.onButtonPress);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustImagesToShow)
        window.removeEventListener("keydown", this.onButtonPress);
    },
    watch: {
        loadedImages(newValue) {
            if (this.images.length != 0 && this.images.length === newValue) {
                this.$store.commit('deactivateLoading');
            }
        }
    }

}
</script>

<style>
.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image_carousel {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.carousel__viewport {
    height: 100%;
}

.carousel__track {
    height: 100%;
    align-items: center;
}

.carousel-image {
    -webkit-user-drag: none;
    /* height: 80%;
    max-width: 50%; */
    max-width: 157px;
    max-height: 217px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}


.carousel-image img {
    -webkit-user-drag: none;
    max-width: 157px;
    max-height: 217px;
    margin: auto;
    display: block;
    user-select: none;
}


.carousel__slide--visible {
    transform: rotateY(0);
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.7s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}


/* Tablet */
@media only screen and (min-width: 481px) {
    .carousel-image {
        -webkit-user-drag: none;
        max-width: 320px;
        max-height: 320px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .carousel-image img {
        -webkit-user-drag: none;
        max-width: 320px;
        max-height: 320px;
        margin: auto;
        display: block;
        user-select: none;
    }
}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {
    .carousel-image {
        -webkit-user-drag: none;
        max-width: 430px;
        max-height: 430px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .carousel-image img {
        -webkit-user-drag: none;
        max-width: 430px;
        max-height: 430px;
        margin: auto;
        display: block;
        user-select: none;
    }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {
    .carousel-image {
        -webkit-user-drag: none;
        max-width: 360px;
        max-height: 360px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .carousel-image img {
        -webkit-user-drag: none;
        max-width: 360px;
        max-height: 360px;
        margin: auto;
        display: block;
        user-select: none;
    }
}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {}
</style>
    