<template>
    <div id="navigation-bar">
        <transition name="fade" mode="out-in">
            <router-link class="link secondary" :to="{ name: parentRouteName }" :key="parentRouteDisplayName">{{
                    parentRouteDisplayName
            }}
            </router-link>
        </transition>
    </div>

</template>
    
<script>
export default {
    name: 'NavigationBar',

    computed: {
        parentRouteDisplayName() {
            if (this.$route.meta.parentRoute == null) {
                return null;
            }
            return this.$route.meta.parentRoute.displayName;
        },
        parentRouteName() {
            if (this.$route.meta.parentRoute == null) {
                return null;
            }
            return this.$route.meta.parentRoute.name;
        },
        routeName() {
            return this.$route.meta.displayName;
        },
    },
}
</script>

<style>
#navigation-bar {
    position: absolute;
    bottom: 37px;
    right: 40px;
    display: flex;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Tablet */
@media only screen and (min-width: 481px) {}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {
    #navigation-bar {
        right: 56px;
        bottom: 56px;
    }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {
    #navigation-bar {
        right: 70px;
        bottom: 65px;
    }
}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {}
</style>
    

