<template>
    <Teleport to="body">
        <div v-if="modelValue" class="modal">

            <div class="current-page">{{ $route.meta.displayName }}</div>
            <div class="modal-content">
                <swiper :slides-per-view="1" :initial-slide="startIndex" loop :unique-nav-elements="false"
                    @slideChange="onSlideChange">
                    <swiper-slide v-for="(image, index) in images" :key="index">
                        <div class="big-image">
                            <div class="image-container">
                                <image-wrapper :src="image.path" />
                                <image-wrapper id="close-button" class="big swiper-no-swiping" @click="close"
                                    :src="require('../assets/icons/close.svg')" />
                                <div class="subtitle secondary">{{ image.subtitle }}</div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <image-wrapper id="close-button" class="small" @click="close"
                    :src="require('../assets/icons/close.svg')" />

            </div>
            <router-link class="header secondary" :to="{ name: 'home' }">Philip Tsetinis</router-link>
        </div>
    </Teleport>
</template>
    
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import 'swiper/css';

export default {
    name: 'FullScreenModal',
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        images: Array,
        modelValue: Boolean,
        startIndex: Number,
    },
    methods: {
        close() {
            this.$emit('update:modelValue', false)
        },
        next() {
            const swiper = document.querySelector('.swiper').swiper;
            swiper.slideNext();
        },
        back() {
            const swiper = document.querySelector('.swiper').swiper;
            swiper.slidePrev();
        },
        onSlideChange(e) {
            this.$emit('slideChange', e.realIndex)
        },
        onButtonPress(e) {
            if (this.modelValue) {
                if (e.key === "ArrowRight") {
                    if (!this.showModal) {
                        this.next();
                    }

                } else if (e.key === "ArrowLeft") {
                    if (!this.showModal) {
                        this.back();
                    }
                }
                else if (e.key === "Escape") {
                    this.close();
                }
            }
        }
    },
    emits: ['update:modelValue', 'slideChange'],
    mounted() {
        window.addEventListener("keydown", this.onButtonPress);
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.onButtonPress);
    }
}
</script>


<style scoped>
.swiper {
    height: 100%;
}

.swiper-wrapper {
    height: 100%;
}

.swiper-slide {
    height: 100%;
}

.modal {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    animation: showBackground .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.header {
    animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-content {
    height: 100%;
    width: 100%;
    animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.big-image {
    position: absolute;
    left: 40px;
    right: 40px;
    top: 40px;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container {
    max-height: 70%;
    position: relative;
}

.image-container img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.3);
    -webkit-user-drag: none;
}

.subtitle {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: start;
    position: absolute;
    top: 100%;
    margin-top: 8px;
    white-space: pre-line;
}

#close-button {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 33px;
    left: 33px;
    z-index: 100;
}

.big {
    display: none
}

@keyframes blowUpModal {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    70% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes showBackground {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (min-height: 400px) {
    .image-container {
        max-height: 200px;
    }

    .image-container img {
        max-height: 200px;
    }
}

@media only screen and (min-height: 450px) {
    .image-container {
        max-height: 260px;
    }

    .image-container img {
        max-height: 260px;
    }
}

@media only screen and (min-height: 500px) {
    .image-container {
        max-height: 280px;
    }

    .image-container img {
        max-height: 280px;
    }
}

@media only screen and (min-height: 550px) {
    .image-container {
        max-height: 320px;
    }

    .image-container img {
        max-height: 320px;
    }
}

@media only screen and (min-height: 600px) {
    .image-container {
        max-height: 370px;
    }

    .image-container img {
        max-height: 370px;
    }
}

@media only screen and (min-height: 650px) {
    .image-container {
        max-height: 410px;
    }

    .image-container img {
        max-height: 410px;
    }
}

@media only screen and (min-height: 700px) {
    .image-container {
        max-height: 460px;
    }

    .image-container img {
        max-height: 460px;
    }
}

@media only screen and (min-height: 750px) {
    .image-container {
        max-height: 510px;
    }

    .image-container img {
        max-height: 510px;
    }
}

@media only screen and (min-height: 800px) {
    .image-container {
        max-height: 560px;
    }
    
    .image-container img {
        max-height: 560px;
    }
}

@media only screen and (min-height: 850px) {
    .image-container {
        max-height: 610px;
    }

    .image-container img {
        max-height: 610px;
    }
}

@media only screen and (min-height: 900px) {
    .image-container {
        max-height: 660px;
    }

    .image-container img {
        max-height: 660px;
    }
}

@media only screen and (min-height: 950px) {
    .image-container {
        max-height: 710px;
    }

    .image-container img {
        max-height: 710px;
    }
}

@media only screen and (min-height: 1000px) {
    .image-container {
        max-height: 760px;
    }

    .image-container img {
        max-height: 760px;
    }
}

@media only screen and (min-height: 1050px) {
    .image-container {
        max-height: 782px;
    }

    .image-container img {
        max-height: 782px;
    }
}


/* Tablet */
@media only screen and (min-width: 481px) {}

/* Tiny Desktop */
@media only screen and (min-width: 768px) {
    .small {
        display: none
    }

    .image-container {
        max-width: 782px;
    }

    .subtitle {
        margin-top: 28px;
        font-size: 13.5px;
        line-height: 16.2px;
    }

    #close-button.big {
        display: unset;
        top: 100%;
        right: -6px;
        margin-top: 24px;
        left: unset;
        box-shadow: none;
    }
}

/* Small Desktop */
@media only screen and (min-width: 992px) {}

/* Medium Desktop */
@media only screen and (min-width: 1200px) {}

/* Large Desktop */
@media only screen and (min-width: 1600px) {}
</style>